import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isNil } from 'ramda'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import CircularProgress from '@material-ui/core/CircularProgress'
import MUIAddIcon from '@material-ui/icons/Add'
import DeleteIcon from 'src/assets/icons/delete.svg'
import CheckIcon from 'src/assets/icons/check.svg'
import XIcon from 'src/assets/icons/close-x.svg'
import EditIcon from 'src/assets/icons/edit.svg'
import { breakpoint } from 'src/theme/grid'

export const Button = styled.button`
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
  overflow: hidden;
  padding: 16px 3.2rem;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.mercury.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  pointer-events: none;
  ${({ active }) =>
    active &&
    css`
      pointer-events: auto;
      color: white;
      background-color: ${({ theme }) => theme.colors.apple.hex()};
      box-shadow: 0 2px 10px 0 rgba(71, 170, 66, 0.25);
    `}
`

export const ButtonPrice = styled(Button)`
  width: 180px;
  border: 2px solid ${({ theme }) => theme.colors.apple};
  color: ${({ theme }) => theme.colors.apple};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.apple};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const ButtonDark = styled(Button)`
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-shadow: 0 2px 10px 0 rgba(36, 45, 63, 0.25);
  font-weight: 800;
  padding: 0 1.2rem;
  height: 40px;
  white-space: nowrap;
  font-size: 0.85rem;
`

export const ButtonLight = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white.hex()};
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  box-shadow: 0 2px 10px 0 rgba(36, 45, 63, 0.25);
  font-weight: 800;
  padding: 0 1.2rem;
  height: 40px;
  white-space: nowrap;
  font-size: 0.85rem;
`

const Loader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
  vertical-align: middle;
  margin-left: 0.8rem;
  color: white !important;
`

const ButtonWithLoaderComponent = ({
  className,
  children,
  loading,
  active,
  onClick,
  type,
  disabled
}) => (
  <Button
    className={className}
    active={active}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {loading ? <Loader /> : children}
  </Button>
)

export const ButtonWithLoader = styled(ButtonWithLoaderComponent)``

export const AddIcon = styled(MUIAddIcon)`
  font-size: 1.2rem;
`

const AddOfferButtonComponent = ({ className, title, ...rest }) => (
  <Button className={className} {...rest}>
    <span>{title || <FormattedMessage id='forms.addOffer' />}</span>
    <AddIcon />
  </Button>
)

const AddOfferButtonComponentDark = ({ className, title = '', ...rest }) => (
  <ButtonDark className={className} {...rest}>
    {title && <span>{title}</span>}
    <AddIcon />
  </ButtonDark>
)

const AddButtonComponent = ({ className, title, loading, ...rest }) => (
  <Button className={className} {...rest}>
    <span>{title || <FormattedMessage id='common.add' />}</span>
    {loading ? <Loader /> : <AddIcon />}
  </Button>
)

const AcceptOfferButtonComponent = ({
  className,
  title,
  hideIcon,
  ...rest
}) => (
  <Button className={className} {...rest}>
    <span>{title || <FormattedMessage id='myOffers.accept' />} </span>
    <CheckIcon />
  </Button>
)

const EditButtonComponent = ({ className, title, hideIcon, ...rest }) => (
  <Button className={className} {...rest}>
    <span>{title || <FormattedMessage id='common.edit' />} </span>
    <EditIcon />
  </Button>
)

const RejectOfferButtonComponent = ({ className, title, ...rest }) => (
  <Button className={className} {...rest}>
    <span>{title || <FormattedMessage id='myOffers.reject' />}</span>
    <XIcon />
  </Button>
)

const RemoveOfferPromptButtonComponent = ({ className, ...rest }) => (
  <Button className={className} {...rest}>
    <span>
      <FormattedMessage id='common.remove' />
    </span>
    <DeleteIcon />
  </Button>
)

const AddUserButtonComponent = ({ className }) => (
  <Button className={className}>
    <span>
      <FormattedMessage id='myCompany.users.addUser' />
    </span>
    <AddIcon />
  </Button>
)

const RemoveButtonComponent = ({ className, loading, ...rest }) => (
  <Button className={className} {...rest}>
    <DeleteIcon />
    <span>
      <FormattedMessage id='common.remove' />
      {loading && <Loader />}
    </span>
  </Button>
)

const addButtonStyles = css`
  background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  font-size: 0.875rem;
  line-height: 1.71;
  font-weight: 600;
  padding: 0 0.4rem;
  height: 35px;
  box-shadow: unset;
  border-radius: 4px;
  transition: all 0.3s;
  ${breakpoint.s`
    padding: 0 0.5rem;
  `}
  & > span {
    display: none;
    margin-right: 0.5rem;
    white-space: nowrap;
    ${breakpoint.s`
      display: inline-block;
    `}
  }
`

export const AddOfferButton = styled(AddOfferButtonComponent)`
  ${addButtonStyles}
`

export const AddOfferButtonDark = styled(AddOfferButtonComponentDark)`
  ${addButtonStyles};
  background-color: ${({ theme }) => theme.colors.ebonyClay.hex()};
`

export const AddUserButton = styled(AddUserButtonComponent)`
  ${addButtonStyles}
`

export const RemoveButton = styled(RemoveButtonComponent)`
  ${addButtonStyles};
  border-radius: 0;
  height: 48px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
`

export const AcceptButton = styled(AcceptOfferButtonComponent)`
  ${addButtonStyles};
  cursor: pointer;
  margin-right: 4px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  ${breakpoint.m`
      min-width: 100px;
      width: auto;
      height: 35px;
  `}
  & > span {
    display: none;
    ${breakpoint.m`
      display: inline-block;
    `}
  }
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.hex()};
    `}
`

export const EditButton = styled(EditButtonComponent)`
  ${addButtonStyles};
  cursor: pointer;
  margin-right: 4px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  ${breakpoint.m`
      min-width: 100px;
      width: auto;
      height: 35px;
  `}
  & > span {
    display: none;
    ${breakpoint.m`
      display: inline-block;
    `}
  }
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.apple.hex()};
    `}
`

export const RejectOfferButton = styled(RejectOfferButtonComponent)`
  ${addButtonStyles};
  cursor: pointer;
  margin-left: 4px;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  border: 1px solid ${({ theme }) => theme.colors.corduroy.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  & > span {
    display: none;
    ${breakpoint.m`
      display: inline-block;
    `}
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
      color: ${({ theme }) => theme.colors.white.hex()};
      border: 1px solid ${({ theme }) => theme.colors.fadedRed.hex()};
      ${breakpoint.m`
      background-color: ${({ theme }) => theme.colors.white.hex()};
      color: ${({ theme }) => theme.colors.fadedRed.hex()};
      border: 1px solid ${({ theme }) => theme.colors.fadedRed.hex()};
  `}
    `}
  ${breakpoint.m`
      min-width: 100px;
      width: auto;
      height: 35px;
  `}
`

export const RejectOfferPromptButton = styled(RejectOfferButton)`
  & {
    ${({ active }) =>
      active &&
      css`
        background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
        color: ${({ theme }) => theme.colors.white.hex()};
        border: 1px solid ${({ theme }) => theme.colors.fadedRed.hex()};
      `}
    ${breakpoint.m`
      min-width: 100px;
  `}
  }
`

export const RemoveOfferPromptButton = styled(RemoveOfferPromptButtonComponent)`
  ${addButtonStyles};
  cursor: pointer;
  margin-left: 4px;
  font-size: 0.875rem;
  font-weight: 600;
  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  border: 1px solid ${({ theme }) => theme.colors.corduroy.hex()};
  background-color: ${({ theme }) => theme.colors.corduroy.hex()};
  color: ${({ theme }) => theme.colors.white.hex()};
  & > span {
    margin-right: 0.5rem;
    white-space: nowrap;
    display: inline-block;
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.fadedRed.hex()};
      color: ${({ theme }) => theme.colors.white.hex()};
      border: 1px solid ${({ theme }) => theme.colors.fadedRed.hex()};
    `}
  ${breakpoint.m`
      min-width: 100px;
  `}
`

export const SaveOfferButton = styled(Button)`
  font-weight: 600;
  height: 40px;
  padding: 0 1.2rem;
  white-space: nowrap;
`

export const SaveButtonWithLoader = styled(ButtonWithLoaderComponent)`
  font-weight: 400;
  height: 40px;
  padding: 0 1.2rem;
`

export const CancelButton = styled(SaveOfferButton)`
  color: ${({ theme }) => theme.colors.black.hex()};
  background: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 0.875rem;
`

export const ReserveButton = styled(ButtonWithLoaderComponent)`
  flex: 1;
  margin-right: 4px;
  margin-left: 24px;
  height: 40px;
  padding: 0.5rem 1rem;
  box-shadow: none;
  font-weight: 600;
  font-size: 0.875rem;
  white-space: nowrap;
  box-sizing: border-box;
  min-width: 180px;
  overflow: hidden;
  position: relative;

  & span {
    display: none;
    line-height: 0rem;
    position: absolute;
    right: 0.5rem;
    & > svg {
      color: inherit;
      opacity: 1;
    }

    ${breakpoint.m`
      display: inline;
      `}
  }
`

export const ChatButton = styled(ReserveButton)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.8).hex()};
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  border: 1px solid ${({ theme }) => theme.colors.athensGrayLight.hex()};
  opacity: 0.5;
  padding-top: 0;
  justify-content: center;
  padding-bottom: 0;
  margin-left: 4px;
  margin-right: 4px;
  &:first-child {
    margin-left: 24px;
  }
  &:last-child {
    margin-right: 24px;
  }
  position: relative;
  ${breakpoint.m`
      padding-right: 32px;
     `}
  & span {
    display: none;
    line-height: 0rem;
    position: absolute;
    right: 0.5rem;
    ${breakpoint.m`
      display: inline;
      `}
  }
  ${Loader} {
    color: ${({ theme }) => theme.colors.ebonyClay.hex()}!important;
  }
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      &:hover svg {
        transition: color 0.3s;
        color: ${({ theme }) => theme.colors.easternBlue.hex()};
      }
    `}
`

export const BidButton = styled(ChatButton)`
  color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.8).hex()};
  background-color: ${({ theme }) => theme.colors.athensGrayLight.hex()};
  border: 1px solid ${({ theme }) => theme.colors.athensGrayLight.hex()};
  -webkit-transform: translate3d(0, 0, 0);

  & span {
    display: none;
    line-height: 0rem;
    position: absolute;
    right: 0.5rem;
    & > svg {
      color: inherit;
      opacity: 1;
    }

    ${breakpoint.m`
      display: inline;
      `}
  }
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.white.hex()};
      border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      background-color: ${({ theme }) => theme.colors.apple.hex()};
    `}
`

export const ReservationsButton = styled(ChatButton)`
  margin-right: 4px;
  margin-left: 24px;
  background-color: ${({ theme }) => theme.colors.ebonyClay};
  border: none;
  color: ${({ theme }) => theme.colors.white};
`

export const AddButton = styled(AddButtonComponent)`
  ${addButtonStyles}
`

export const ButtonOutlined = styled(ButtonWithLoaderComponent)`
  border: 2px solid ${({ theme }) => theme.colors.apple.hex()};
  color: ${({ theme }) => theme.colors.apple.hex()};
  background-color: ${({ theme }) => theme.colors.white.hex()};
  box-shadow: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.apple.hex()};
    color: ${({ theme }) => theme.colors.white.hex()};
  }
`

export const DelayedOfferButton = ({
  onClick,
  children,
  availableTime,
  offerId,
  loading,
  active,
  component
}) => {
  const DisplayedComponent = component || ReserveButton
  const intl = useIntl()
  const [timeLeft, setTimeLeft] = useState(0)
  const buttonActive = isNil(timeLeft) || timeLeft <= 0

  useEffect(() => {
    const timeLeftValue =
      availableTime !== null
        ? moment(availableTime).diff(moment(), 'seconds')
        : null

    if (isNil(timeLeftValue) || timeLeftValue <= 0) {
      setTimeLeft(0)
      return () => {}
    }

    setTimeLeft(timeLeftValue)

    const ref = setInterval(() => {
      setTimeLeft(v => v - 1)
    }, 1000)

    return () => clearInterval(ref)
  }, [offerId])

  return (
    <DisplayedComponent
      loading={loading}
      active={buttonActive && active}
      onClick={buttonActive && active ? onClick : () => {}}
    >
      {buttonActive
        ? children
        : `${intl.formatMessage({
            id: 'offerDetails.reservationAvailableIn'
          })} ${moment.utc(timeLeft * 1000).format('mm:ss')}`}
    </DisplayedComponent>
  )
}
